import FacebookIcon from "../../assets/svgs/facebook.svg"
import InstagramIcon from "../../assets/svgs/instagram.svg"
import LinkedInIcon from "../../assets/svgs/linkedin.svg"
import YoutubeIcon from "../../assets/svgs/youtube.svg"

export const ADDRESS = `Baía Sul Medical Center Sala 115
Rua Menino Deus, 63
Centro, Florianópolis - SC
88020-210
`
export const PHONES = [`(48) 3207-2050`]
export const EMAIL = [`contato@gastrocirurgica.com.br`]

export const SOCIAL = [
  {
    alt: `Facebook`,
    url: `https://www.facebook.com/gastrocirurgica/`,
    icon: FacebookIcon,
  },
  {
    alt: `Instagram`,
    url: `https://www.instagram.com/gastrocirurgicaflorianopolis/`,
    icon: InstagramIcon,
  },
  {
    alt: `LinkedIn`,
    url: `https://www.linkedin.com/company/gastrocir%C3%BArgica`,
    icon: LinkedInIcon,
  },
  {
    alt: `Youtube`,
    url: `https://www.youtube.com/channel/UCr0U5s0HsZVKFNGTDib6K-g`,
    icon: YoutubeIcon,
  },
]
