import React, { memo } from "react"
import Logo from "../logo"
import Container from "../container"
import Flex from "../flex"
import Button from "../button"
import Typography from "../typography"
import AddressIcon from "../../assets/svgs/address.svg"
import PhoneIcon from "../../assets/svgs/phone.svg"
import EmailIcon from "../../assets/svgs/email.svg"
import MedicalIcon from "../../assets/svgs/medical.svg"
import StyledFooter from "./styled"
import LabelIcon from "../labelIcon"
import { ADDRESS, PHONES, EMAIL, SOCIAL } from "./constants"

const Footer = () => (
  <StyledFooter>
    <Container>
      <Flex align="flex-start" flexWrap="wrap">
        <Flex child flex={100} flexGtXs={25} spacing={2}>
          <Logo />
        </Flex>
        <Flex child flex={100} flexGtXs={25} spacing={2}>
          <LabelIcon icon={MedicalIcon}>
            <Typography variant="bodySmall">Diretor técnico:</Typography>
            <Typography variant="bodyLarge" removeMargin>
              Dr Eduardo Miguel Schmidt
            </Typography>
            <Typography variant="bodySmall">CRM SC 7120</Typography>
            <br />
            <Typography variant="bodySmall">
              Cirurgia do Aparelho Digestivo (RQE 3330)
            </Typography>
            <br />
            <Typography variant="bodySmall">
              Cirurgia Geral (RQE 3293)
            </Typography>
          </LabelIcon>
        </Flex>
        <Flex child flex={100} flexGtXs={25} spacing={2}>
          <LabelIcon icon={AddressIcon}>
            <Typography variant="bodySmall">{ADDRESS}</Typography>
          </LabelIcon>
          {PHONES.map(phone => (
            <LabelIcon icon={PhoneIcon} key={phone}>
              <a href={`tel:${phone}`}>
                <Typography variant="bodySmall">{phone}</Typography>
              </a>
            </LabelIcon>
          ))}
          <LabelIcon icon={EmailIcon}>
            <a href={`mailto:${EMAIL}`}>
              <Typography variant="bodySmall">{EMAIL}</Typography>
            </a>
          </LabelIcon>
        </Flex>
        <Flex child flex={100} flexGtXs={25} spacing={2}>
          <br />
          {SOCIAL.map(({ icon, alt, url }) => (
            <Button
              key={alt}
              icon={icon}
              alt={alt}
              href={url}
              target="_blank"
              htmlType="a"
              color="primary"
              shape="circle"
            />
          ))}
        </Flex>
      </Flex>
      <Flex align="center" justify="center" style={{ marginTop: "2rem" }}>
        <Flex child flex="none">
          <Typography variant="body" removeMargin>
            <span>&copy; Copyright 2019. Por </span>
            <Button
              color="link"
              href="http://lotusexperience.com.br/"
              htmlType="a"
              target="_blank"
            >
              Lótus Experience
            </Button>
          </Typography>
        </Flex>
      </Flex>
    </Container>
  </StyledFooter>
)

export default memo(Footer)
