import styled from "styled-components"
import SIZES_STYLES from "./sizes"

export default styled.div`
  ${({ styles }) => (styles ? { ...styles } : "")}
  ${({ flex }) => SIZES_STYLES[flex]}
  @media (max-width: 600px) {
    ${({ flexXs }) => SIZES_STYLES[flexXs]}
  }
  @media (min-width: 600px) {
    ${({ flexGtXs }) => SIZES_STYLES[flexGtXs]}
  }
  @media (min-width: 600px) and (max-width: 960px) {
    ${({ flexSm }) => SIZES_STYLES[flexSm]}
  }
  @media (min-width: 960px) {
    ${({ flexGtSm }) => SIZES_STYLES[flexGtSm]}
  }
  @media (min-width: 960px) and (max-width: 1280px) {
    ${({ flexMd }) => SIZES_STYLES[flexMd]}
  }
  @media (min-width: 1280px) {
    ${({ flexGtMd }) => SIZES_STYLES[flexGtMd]}
  }
  @media (min-width: 1280px) and (max-width: 1920px) {
    ${({ flexLg }) => SIZES_STYLES[flexLg]}
  }
  @media (min-width: 1920px) {
    ${({ flexGtLg }) => SIZES_STYLES[flexGtLg]}
  }
`
