import {
  BUTTON_SIZE_DEFAULT,
  BUTTON_SIZE_LARGE,
  BUTTON_SIZE_SMALL,
} from "./constants"

export default {
  [BUTTON_SIZE_DEFAULT]: `
    padding: 10px;
  `,
  [BUTTON_SIZE_LARGE]: `
    padding: 16px;
  `,
  [BUTTON_SIZE_SMALL]: `
    padding: 4px;
  `,
}
