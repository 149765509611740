import styled from "styled-components"

export const StyledLogo = styled.img`
  max-width: 100%;
  margin: 0;
`

export default styled.figure`
  max-width: 310px;
  display: flex;
  align-items: center;
  margin: 0;
  @media (max-width: 688px) {
    max-width: 250px;
  }
`
