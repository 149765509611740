import React, { memo } from "react"
import PropTypes from "prop-types"
import VARIANTS, { COLORS, COLOR_DEFAULT } from "./constants"

const Typography = ({
  children,
  component,
  removeMargin,
  variant,
  color,
  pre,
  ...props
}) => {
  const variantEl = VARIANTS[variant]
  const Component = component || variantEl.defaultElement

  const style = {
    ...variantEl.style,
    marginBottom: removeMargin ? "0" : "1rem",
    color: COLORS[color],
    ...(pre ? { whiteSpace: "pre-line" } : {}),
    ...(component === "a" ? { textDecoration: "underline" } : {}),
  }

  return (
    <Component style={style} {...props}>
      {children}
    </Component>
  )
}

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.elementType,
  variant: PropTypes.oneOf(Object.keys(VARIANTS)).isRequired,
  removeMargin: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(COLORS)),
  pre: PropTypes.bool,
}

Typography.defaultProps = {
  component: null,
  removeMargin: false,
  color: COLOR_DEFAULT,
  pre: false,
}

export default memo(Typography)
