import React, { memo } from "react"
import PropTypes from "prop-types"
import Parent from "./parent"
import Child from "./child"
import { SIZES, DEFAULT } from "./constants"

const Flex = ({
  flex,
  flexWrap,
  column,
  justify,
  align,
  spacing,
  child,
  gap,
  ...props
}) => {
  if (child) {
    return <Child spacing={spacing} flex={flex} {...props} />
  }
  return (
    <Parent
      flex={flex}
      justify={justify}
      align={align}
      flexWrap={flexWrap}
      column={column}
      gap={gap}
      {...props}
    />
  )
}

Flex.propTypes = {
  flex: PropTypes.oneOf(SIZES),
  flexXs: PropTypes.oneOf(SIZES),
  flexGtXs: PropTypes.oneOf(SIZES),
  flexMd: PropTypes.oneOf(SIZES),
  flexGtMd: PropTypes.oneOf(SIZES),
  flexLg: PropTypes.oneOf(SIZES),
  flexGtLg: PropTypes.oneOf(SIZES),
  flexXl: PropTypes.oneOf(SIZES),
  flexGtXl: PropTypes.oneOf(SIZES),
  flexWrap: PropTypes.oneOf(["wrap", "wrap-reverse", "nowrap"]),
  column: PropTypes.bool,
  justify: PropTypes.oneOf([
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
  align: PropTypes.oneOf([
    "flex-start",
    "flex-end",
    "stretch",
    "center",
    "baseline",
  ]),
  child: PropTypes.bool,
  spacing: PropTypes.number,
  gap: PropTypes.number,
}

Flex.defaultProps = {
  flex: DEFAULT,
  flexXs: undefined,
  flexGtXs: undefined,
  flexMd: undefined,
  flexGtMd: undefined,
  flexLg: undefined,
  flexGtLg: undefined,
  flexXl: undefined,
  flexGtXl: undefined,
  flexWrap: "nowrap",
  column: false,
  justify: "flex-start",
  align: "stretch",
  child: false,
  spacing: 0,
  gap: null,
}

export default memo(Flex)
