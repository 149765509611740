import React, { memo } from "react"
import PropTypes from "prop-types"
import Flex from "../flex"
import { PRIMARY_COLOR } from "../../utils/colors"

const LabelIcon = ({ icon: Icon, children }) => (
  <Flex style={{ margin: "0.5rem 0" }}>
    <Flex child flex="none" spacing={1}>
      <Icon style={{ fill: PRIMARY_COLOR }} />
    </Flex>
    <Flex child>{children}</Flex>
  </Flex>
)

LabelIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default memo(LabelIcon)
