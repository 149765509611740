import {
  BUTTON_SHAPE_DEFAULT,
  BUTTON_SHAPE_CIRCLE,
  BUTTON_SHAPE_ROUND,
} from "./constants"

export default {
  [BUTTON_SHAPE_DEFAULT]: `
    border-radius: 4px;
  `,
  [BUTTON_SHAPE_CIRCLE]: `
    border-radius: 100%;
  `,
  [BUTTON_SHAPE_ROUND]: `
    border-radius: 50px;
  `,
}
