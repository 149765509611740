import styled from "styled-components"
import Button from "../button"
import { LIGHT_COLOR } from "../../utils/colors"

export const StyledNavDesktop = styled.nav`
  @media (max-width: 959px) {
    display: none;
  }
`
export const StyledButton = styled(Button)`
  @media (min-width: 960px) {
    display: none !important;
  }
`

export const StyledNavMobile = styled.nav`
  @media (min-width: 960px) {
    display: none;
  }
  position: fixed;
  top: 79px;
  left: 0;
  right: 0;
  background: white;
  padding: 2rem;
  a {
    padding: 1rem 0 !important;
  }
`

export default styled.header`
  z-index: 2;
  position: sticky;
  top: 0;
  padding: 0.5rem 0;
  background: ${LIGHT_COLOR};
`
