import styled from "styled-components"
import CONTAINER from "../../utils/container"

export default styled.div`
  width: 100%;
  max-width: ${CONTAINER}px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.5rem;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: ${({ padding = "0 1.5rem" }) => padding};
  }
`
