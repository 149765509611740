const DEFAULT = "default"
export const BUTTON_COLOR_DEFAULT = DEFAULT
export const BUTTON_COLOR_PRIMARY = "primary"
export const BUTTON_COLOR_SECONDARY = "secondary"
export const BUTTON_COLOR_SUCCESS = "success"
export const BUTTON_COLOR_DANGER = "danger"
export const BUTTON_COLOR_LINK = "link"
export const BUTTON_COLOR_HEADER = "header"
export const BUTTON_SHAPE_DEFAULT = DEFAULT
export const BUTTON_SHAPE_CIRCLE = "circle"
export const BUTTON_SHAPE_ROUND = "round"
export const BUTTON_SIZE_DEFAULT = DEFAULT
export const BUTTON_SIZE_LARGE = "large"
export const BUTTON_SIZE_SMALL = "small"
export const HTML_TYPE_BUTTON = "button"
export const HTML_TYPE_LINK = "link"
export const HTML_TYPE_A = "a"

export const BUTTON_COLORS = [
  BUTTON_COLOR_DEFAULT,
  BUTTON_COLOR_PRIMARY,
  BUTTON_COLOR_SECONDARY,
  BUTTON_COLOR_SUCCESS,
  BUTTON_COLOR_DANGER,
  BUTTON_COLOR_LINK,
  BUTTON_COLOR_HEADER,
]
export const BUTTON_SHAPES = [
  BUTTON_SHAPE_DEFAULT,
  BUTTON_SHAPE_CIRCLE,
  BUTTON_SHAPE_ROUND,
]
export const BUTTON_SIZES = [
  BUTTON_SIZE_DEFAULT,
  BUTTON_SIZE_LARGE,
  BUTTON_SIZE_SMALL,
]

export const HTML_TYPES = [HTML_TYPE_LINK, HTML_TYPE_BUTTON, HTML_TYPE_A]
