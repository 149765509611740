import styled from "styled-components"
import { Link } from "gatsby"
import STYLES_BUTTON_COLORS from "./colors"
import STYLES_BUTTON_GHOST from "./ghost"
import STYLES_BUTTON_SHAPES from "./shapes"
import STYLES_BUTTON_SIZES from "./sizes"
import { PRIMARY_COLOR } from "../../utils/colors"

export const StyledLoading = styled.div`
  margin: 2px 7px 0 5px;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  border: 2px dashed;
  animation: spin 1.8s infinite linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
export const StyledIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0;
`

export default styled(Link)`
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-family: "PT Sans Caption";
  font-weight: bold;
  font-size: 16px;
  margin: 4px;
  text-decoration: none;
  outline: none !important;
  span:not(:empty) {
    padding: 0 4px;
  }
  ${({ ghost }) =>
    ghost
      ? `
          background: transparent;
          border: 1px solid;
          &:hover {
            color: white;
          }
        `
      : ``}
  ${({ disabled }) =>
    disabled
      ? `
          pointer-events: none;
        `
      : ``}
  ${({ fixed }) =>
    fixed
      ? `
          position: fixed;
          bottom: 20px;
          right: 20px;
        `
      : ``}
  ${({ block }) =>
    block
      ? `
        display: block;
        text-align: center;
      `
      : ``}
  ${({ shape }) => STYLES_BUTTON_SHAPES[shape]}
  ${({ size }) => STYLES_BUTTON_SIZES[size]}
  ${({ active }) =>
    active === "true" ? `color: ${PRIMARY_COLOR} !important;` : ``}
  ${({ color, ghost }) =>
    ghost ? STYLES_BUTTON_GHOST[color] : STYLES_BUTTON_COLORS[color]}
`
