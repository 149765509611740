import styled from "styled-components"
import Flex from "./flex"

export default styled(Flex)`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  ${({ gap }) => (gap ? `gap: ${gap}rem;` : "")}
`
