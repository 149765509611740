import {
  DEFAULT,
  NONE,
  INITIAL,
  AUTO,
  GROWN,
  NOGROW,
  NOSHRINK,
  NUMBER_SIZES,
} from "./constants"

const NUMBER_SIZES_STYLES = NUMBER_SIZES.reduce((sizes, size) => {
  return {
    ...sizes,
    [size]: `
      flex: 1 1 100%;
      max-width: ${size}%;
    `,
  }
}, {})

export default {
  [DEFAULT]: `
    flex: 1;
    min-width: 0;
  `,
  [NONE]: `
    flex: 0 0 auto;
  `,
  [INITIAL]: `
    flex: 0 1 auto;
  `,
  [AUTO]: `
    flex: 1 1 auto;
  `,
  [GROWN]: `
    flex: 1 1 100%;
  `,
  [NOGROW]: `
    flex: 0 1 auto;
  `,
  [NOSHRINK]: `
    flex: 1 0 auto;
  `,
  ...NUMBER_SIZES_STYLES,
}
