import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SECONDARY_LIGHT_COLOR,
  TERTIARY_COLOR,
  SUCCESS_COLOR,
  DANGER_COLOR,
  DARK_COLOR,
  LIGHT_COLOR,
  LINK_COLOR,
} from "../../utils/colors"

export const COLOR_DEFAULT = "dark"

export const COLORS = {
  primary: PRIMARY_COLOR,
  secondary: SECONDARY_COLOR,
  secondary_light: SECONDARY_LIGHT_COLOR,
  tertiary: TERTIARY_COLOR,
  success: SUCCESS_COLOR,
  danger: DANGER_COLOR,
  dark: DARK_COLOR,
  light: LIGHT_COLOR,
  link: LINK_COLOR,
}

export default {
  h1ExtraLarge: {
    style: { fontWeight: "700", fontSize: "6rem", lineHeight: "6rem" },
    defaultElement: "h1",
  },
  h1Big: {
    style: { fontWeight: "700", fontSize: "3.4rem", lineHeight: "3.7rem" },
    defaultElement: "h1",
  },
  h1: {
    style: { fontWeight: "700", fontSize: "2.8rem", lineHeight: "3.1rem" },
    defaultElement: "h1",
  },
  h2: {
    style: { fontWeight: "700", fontSize: "2.4rem", lineHeight: "2.7rem" },
    defaultElement: "h2",
  },
  h3: {
    style: { fontWeight: "700", fontSize: "1.9rem", lineHeight: "2.2rem" },
    defaultElement: "h3",
  },
  h4: {
    style: { fontWeight: "700", fontSize: "1.5rem", lineHeight: "1.8rem" },
    defaultElement: "h4",
  },
  h5: {
    style: { fontWeight: "700", fontSize: "1.3rem", lineHeight: "1.6rem" },
    defaultElement: "h5",
  },
  h6: {
    style: { fontWeight: "700", fontSize: "1.1rem", lineHeight: "1.4rem" },
    defaultElement: "h6",
  },
  bodyLarge: {
    style: { fontSize: "1.2rem", lineHeight: "1.6rem" },
    defaultElement: "p",
  },
  body: {
    style: { fontSize: "1rem", lineHeight: "1.3rem" },
    defaultElement: "p",
  },
  bodySmall: {
    style: { fontSize: "0.9rem", lineHeight: "1.2rem" },
    defaultElement: "span",
  },
  bodySmallest: {
    style: { fontSize: "0.76rem", lineHeight: "1rem" },
    defaultElement: "span",
  },
}
