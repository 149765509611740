export default [
  {
    label: "Início",
    url: "/home",
  },
  {
    label: "Equipe",
    url: "/equipe",
  },
  {
    label: "Especialidades",
    url: "/especialidades",
  },
  {
    label: "Doenças tratadas",
    url: "/doencastratadas",
  },
  {
    label: "Onde atendemos",
    url: "/ondeatendemos",
  },
  {
    label: "Contato",
    url: "/contato",
  },
]
