import React, { memo, useState, useRef } from "react"
import useOnClickOutside from "use-onclickoutside"
import PropTypes from "prop-types"
import Logo from "../logo"
import Button from "../button"
import Container from "../container"
import Flex from "../flex"
import StyledHeader, {
  StyledButton,
  StyledNavDesktop,
  StyledNavMobile,
} from "./styled"
import PAGES from "./constants"
import MenuIcon from "../../assets/svgs/menu.svg"

const Header = ({ location }) => {
  const ref = useRef(null)
  const pathname = location.pathname === "/" ? "/home" : location.pathname
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  useOnClickOutside(ref, handleClose)
  return (
    <StyledHeader>
      <Container>
        <Flex align="center">
          <Flex child flex="grown">
            <Logo />
          </Flex>
          <Flex child flex="none">
            <StyledNavDesktop>
              {PAGES.map(page => (
                <Button
                  key={page.label}
                  color="header"
                  htmlType="link"
                  to={page.url === "/home" ? "/" : page.url}
                  active={pathname.includes(page.url) ? "true" : "false"}
                >
                  {page.label}
                </Button>
              ))}
            </StyledNavDesktop>
            <StyledButton
              onClick={handleOpen}
              icon={MenuIcon}
              shape="circle"
              color="link"
            />
            <div ref={ref}>
              {isOpen && (
                <StyledNavMobile>
                  <Flex column>
                    {PAGES.map(page => (
                      <Flex child>
                        <Button
                          key={page.label}
                          color="header"
                          htmlType="link"
                          to={page.url === "/home" ? "/" : page.url}
                          active={
                            pathname.includes(page.url) ? "true" : "false"
                          }
                        >
                          {page.label}
                        </Button>
                      </Flex>
                    ))}
                  </Flex>
                </StyledNavMobile>
              )}
            </div>
          </Flex>
        </Flex>
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default memo(Header)
