import { darken } from "polished"
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERTIARY_COLOR,
  SUCCESS_COLOR,
  DANGER_COLOR,
  LINK_COLOR,
} from "../../utils/colors"
import {
  BUTTON_COLOR_DEFAULT,
  BUTTON_COLOR_PRIMARY,
  BUTTON_COLOR_SECONDARY,
  BUTTON_COLOR_SUCCESS,
  BUTTON_COLOR_DANGER,
  BUTTON_COLOR_LINK,
} from "./constants"

export default {
  [BUTTON_COLOR_DEFAULT]: `
    color: ${TERTIARY_COLOR};
    &:hover {
      background-color: ${TERTIARY_COLOR};
    }
    &:active {
      background-color: ${darken(0.05, TERTIARY_COLOR)};
    }
  `,
  [BUTTON_COLOR_PRIMARY]: `
    color: ${PRIMARY_COLOR};
    &:hover {
      background-color: ${darken(0.05, PRIMARY_COLOR)};
    }
    &:active {
      background-color: ${darken(0.1, PRIMARY_COLOR)};
    }
  `,
  [BUTTON_COLOR_SECONDARY]: `
    color: ${SECONDARY_COLOR};
    &:hover {
      background-color: ${darken(0.05, SECONDARY_COLOR)};
    }
    &:active {
      background-color: ${darken(0.1, SECONDARY_COLOR)};
    }
  `,
  [BUTTON_COLOR_SUCCESS]: `
    color: ${SUCCESS_COLOR};
    &:hover {
      background-color: ${darken(0.05, SUCCESS_COLOR)};
    }
    &:active {
      background-color: ${darken(0.1, SUCCESS_COLOR)};
    }
  `,
  [BUTTON_COLOR_DANGER]: `
    color: ${DANGER_COLOR};
    &:hover {
      background-color: ${darken(0.05, DANGER_COLOR)};
    }
    &:active {
      background-color: ${darken(0.1, DANGER_COLOR)};
    }
  `,
  [BUTTON_COLOR_LINK]: `
    color: ${LINK_COLOR};
    border-color: transparent !important;
    &:hover {
      color: ${darken(0.05, LINK_COLOR)};
    }
    &:active {
      color: ${darken(0.1, LINK_COLOR)};
    }
  `,
}
