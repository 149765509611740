import React, { memo } from "react"
import PropTypes from "prop-types"
import StyledLink, { StyledLoading, StyledIcon } from "./styled"
import {
  BUTTON_COLORS,
  BUTTON_SHAPES,
  BUTTON_SIZES,
  BUTTON_COLOR_DEFAULT,
  BUTTON_SHAPE_DEFAULT,
  BUTTON_SIZE_DEFAULT,
  HTML_TYPES,
  HTML_TYPE_BUTTON,
  HTML_TYPE_LINK,
} from "./constants"

const Button = ({ children, loading, icon: IconSvg, htmlType, ...rest }) => {
  const ButtonComponent =
    htmlType === HTML_TYPE_LINK
      ? StyledLink
      : StyledLink.withComponent(htmlType)

  const Icon = IconSvg && StyledIcon.withComponent(IconSvg)

  return (
    <ButtonComponent {...rest}>
      {loading && <StyledLoading />}
      {Icon && !loading && <Icon />}
      <span>{children}</span>
    </ButtonComponent>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(BUTTON_COLORS),
  shape: PropTypes.oneOf(BUTTON_SHAPES),
  size: PropTypes.oneOf(BUTTON_SIZES),
  loading: PropTypes.bool,
  icon: PropTypes.func,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  fixed: PropTypes.bool,
  htmlType: PropTypes.oneOf(HTML_TYPES),
  target: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.string,
}

Button.defaultProps = {
  children: undefined,
  color: BUTTON_COLOR_DEFAULT,
  shape: BUTTON_SHAPE_DEFAULT,
  size: BUTTON_SIZE_DEFAULT,
  loading: undefined,
  icon: undefined,
  block: undefined,
  disabled: undefined,
  ghost: undefined,
  fixed: undefined,
  htmlType: HTML_TYPE_BUTTON,
  target: undefined,
  onClick: undefined,
  active: "false",
}

export default memo(Button)
