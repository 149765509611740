export const DEFAULT = "default" // Will grow and shrink as needed. Starts with a size of 0%.
export const NONE = "none" // Will not grow or shrink. Sized based on its width and height values.
export const INITIAL = "initial" // Will shrink as needed. Starts with a size based on its width and height values.
export const AUTO = "auto" // Will grow and shrink as needed. Starts with a size based on its width and height values.
export const GROWN = "grown" // Will grow and shrink as needed. Starts with a size of 100%.
export const NOGROW = "nogrow" // Will shrink as needed, but won't grow. Starts with a size based on its width and height values.
export const NOSHRINK = "noshrink" // Will grow as needed, but won't shrink. Starts with a size based on its width and height values.

export const NAMED_SIZES = [
  DEFAULT,
  NONE,
  INITIAL,
  AUTO,
  GROWN,
  NOGROW,
  NOSHRINK,
]

export const NUMBER_SIZES = [
  5,
  10,
  15,
  20,
  25,
  30,
  33,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  66,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
]

export const SIZES = [...NAMED_SIZES, ...NUMBER_SIZES]
