import React, { memo } from "react"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.png"
import StyledFigure, { StyledLogo } from "./styled"

const Logo = () => (
  <Link to="/">
    <StyledFigure>
      <StyledLogo src={logo} alt="GastroCirúrgica" />
    </StyledFigure>
  </Link>
)

export default memo(Logo)
