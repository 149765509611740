export const PRIMARY_COLOR = "#B84D1F"
export const SECONDARY_COLOR = "#00344E"
export const SECONDARY_LIGHT_COLOR = "#00678D"
export const TERTIARY_COLOR = "#B6CBD8"
export const TERTIARY_LIGHT_COLOR = "#D9E5EC"
export const SUCCESS_COLOR = "#57BB63"
export const DANGER_COLOR = "#FF0C0C"
export const DARK_COLOR = "#333333"
export const LIGHT_COLOR = "#FFFFFF"
export const LINK_COLOR = "#2F80ED"
